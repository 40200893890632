<template>
  <a-modal :visible="visible" :title="title">
    <div>
      <a-input
        class="model-input"
        v-model:value="seachVal"
        placeholder="请输入员工姓名或部门"
        @change="toChange"
        allow-clear
      >
        <template #prefix>
          <SearchOutlined style="color: rgba(0, 0, 0, 0.25)" />
        </template>
      </a-input>
    </div>
    <div class="model-breadcrumb">
      <a-breadcrumb>
        <template #separator>
          <span style="color: black">></span>
        </template>
        <a-breadcrumb-item
          href=""
          v-for="(item, index) in breadcrumbList"
          @click="handledBreadcrumb(item, index)"
          :key="index"
          >{{ item.label }}</a-breadcrumb-item
        >
      </a-breadcrumb>
    </div>
    <div class="left-list">
      <template v-if="renderList.length">
        <div v-for="item in renderList" :key="item.id" class="model-item">
          <a-checkbox :checked="getCheckState(item)" class="model-checkbox" @change="handledChange(item)">
            <div class="model-checkbox-item">
              <img class="model-checkbox-img" :src="item.icon" />
              <div class="model-checkbox-name single-wrap">{{ item.dataName }}</div>
            </div>
          </a-checkbox>
          <div v-if="item.islower" class="model-lower-level" @click.stop="tolowerMdel(item.departmentId, item, '')">
            <span class="model-title">下级</span>
            <RightOutlined />
          </div>
        </div>
      </template>
      <a-empty v-else :image="simpleImage" />
    </div>
  </a-modal>
</template>

<script>
import { SearchOutlined, RightOutlined } from '@ant-design/icons-vue'
import { reactive, toRefs } from 'vue'
import { Empty } from 'ant-design-vue'
export default {
  props: {
    visible: {
      type: Boolean,
      default: () => false
    },
    title: {
      type: String,
      default: () => '上级部门选择'
    }
  },
  components: {
    SearchOutlined,
    RightOutlined
  },
  setup() {
    const state = reactive({
      breadcrumbList: [
        { label: '一级部门' },
        { label: '一级部门' },
        { label: '一级部门' },
        { label: '一级部门' },
        { label: '一级部门' },
        { label: '一级部门' },
        { label: '一级部门' }
      ],
      renderList: [
        { id: 1, dataName: '一级部门', islower: true },
        { id: 2, dataName: '二级部门', islower: true },
        { id: 3, dataName: '三级部门', islower: true },
        { id: 4, dataName: '四级部门', islower: true },
        { id: 5, dataName: '五级部门', islower: true }
      ],
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE
    })

    const getCheckState = item => {}
    return {
      ...toRefs(state),
      getCheckState
    }
  }
}
</script>

<style scoped lang="scss">
.model-breadcrumb {
  margin-top: 12px;
}
.left-list {
  margin-top: 12px;
  overflow: scroll;
  max-height: 300px;
  padding-bottom: 20px;
  .model-lower-level {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #c3161c;
    cursor: pointer;
    .model-title {
      margin-right: 12px;
    }
  }
  .model-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .model-checkbox {
      flex: 1;
      padding-right: 8px;
      display: flex;
      align-items: center;
      .model-checkbox-item {
        display: flex;
        align-items: center;
        .model-checkbox-img {
          width: 26px;
          height: 26px;
          border-radius: 50%;
          margin-left: 2px;
          margin-right: 8px;
        }

        .model-checkbox-name {
          display: inline-block;
          max-width: 125px;
        }
      }
    }
  }
}
</style>
